
import t1 from "../../../assets/tweet-1.png"
import t2 from "../../../assets/tweet-2.png"
import t3 from "../../../assets/tweet-3.png"

export const IMAGES = [
    {
      id: 0,
      imageSrc: t1
    },
    {
      id: 1,
      imageSrc: t2
    },
    {
      id: 2,
      imageSrc: t3
    }
  ]
  